import { DEFAULT } from 'constants/enums';
import { updateObject } from 'utils/helper';

const RESET = 'currencies/RESET';
const UPDATE_ALL_CURRENCIES = 'currencies/UPDATE_ALL_CURRENCIES';
const UPDATE_CURRENCY = 'currencies/UPDATE_CURRENCY';

export const initialState = {
	allCurrencies: null,
	checkedCurrency: DEFAULT.CURRENCY,
	previousCurrency: null
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case RESET:
			return updateObject(state, initialState);
		case UPDATE_ALL_CURRENCIES: return updateObject(state, ({ allCurrencies: payload }));
		case UPDATE_CURRENCY: return updateObject(state, ({
			checkedCurrency: payload,
			previousCurrency: state.checkedCurrency
		}));
		default:
			return state;
	}
};

export const resetApp = () => {
	return { type: RESET };
};

export const updateAllCurrencies = array => ({ type: UPDATE_ALL_CURRENCIES, payload: array });

export const updateCurrency = checkedCurrency => ({ type: UPDATE_CURRENCY, payload: checkedCurrency });
