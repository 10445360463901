import React from 'react';

export const DIRECTION = { BOTTOM: 0, TOP: 1};

export default function AppMobile({ children }) {
	return (
		<div>
			<div className='container bg-light'>
				{children}
			</div>
		</div>
	);
}
