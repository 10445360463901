import React, { createContext, useState } from 'react';
import {useSetState} from 'react-use';

export const HEADER_TYPE = { DEFAULT: 0, ARTICLE: 1 };

const defaultState = {
	header: {
		type: HEADER_TYPE.DEFAULT,
		title: null,
		isMain: false,
		isSelect: false,
		isLimit: false,
		isSelectProduct: false,
		isLimitProduct: false
	},
	isFixedHide: false,
	setHeader:() => {}
};

const Context = createContext(defaultState);

function HeaderProvider({ children }) {
	const [header, setState] = useSetState(defaultState.header);
	const [isFixedHide, setFixedHide] = useState(defaultState.isFixedHide);

	const setHeader = state => {
		setState({
			...defaultState.header,
			...state
		});
	};

	return (
		<Context.Provider
			value={{
				header,
				setHeader,
				isFixedHide,
				setFixedHide
			}}
		>
			{children}
		</Context.Provider>
	);
}

export { HeaderProvider };

export default Context;
