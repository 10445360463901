import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import tw, { styled } from 'twin.macro';
import { isSafariBrowser } from 'utils/helper';

import AppMobile from './AppMobile';

// const Landscape = styled.div`
//   ${tw`flex flex-col w-screen h-screen items-center justify-center bg-grey-8 px-20 z-max fixed left-0 bottom-0`};
//   > p { ${ tw`text-light text-12 font-semibold text-center` }}
// `;

const Portrait = styled.div`
  ${tw`flex flex-col h-full items-center justify-center bg-grey-8 px-20 z-max fixed left-0 top-0`};
  > p { ${ tw`text-light text-12 font-semibold text-center` }}
`;

export default function App({ children }) {
	const isNotSupportedDevice = useMediaQuery({ maxDeviceWidth: 319 });
	const isPortrait = useMediaQuery({ orientation: 'portrait' });
	const isLandscape = useMediaQuery({ orientation: 'landscape' });
	const isRetina = useMediaQuery({ minResolution: '2dppx' });

	useEffect(() => {
		if (typeof window === 'undefined' || typeof document === 'undefined') return;

		const isSafari = isSafariBrowser();
		console.log('isRetina', isRetina);

		if(isSafari) {
			// TODO: try "tabindex=0" for div at top of page
			const element = document.getElementsByTagName('meta')['viewport'];
			element.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
		}
	}, []);

	return (
		<div>
			{isNotSupportedDevice && <Portrait><p>Urządzenie nie posiada wystarczającej szerokości ekranu.</p></Portrait>}
			<AppMobile isPortrait>{children}</AppMobile>
		</div>
	);
}
