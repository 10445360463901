export const NAV = { NOT_VISIBLE: -1, MENU: 0, ACADEMY: 1, PRODUCTS: 2, CONTACT: 3 };

export const DEFAULT = { COUNTRY: '', CURRENCY: 'PLN', INIT_AMOUNT: 250000, MIN_AMOUNT: 5000, MAX_AMOUNT: 500000, INIT_DAYS: 30, MAX_DAYS: 150, INIT_INSTALLMENT: 50 };

export const INPUT = {
	KEY: { SUBMIT: 13 }
};

export const FORM = { INCOME: '1', LOSS: '2' };

export const TYPE = { SELECT: 'select', LIMIT: 'limit' };
