module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://smartfaktor.pl/api/graphql/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Smart Faktor S.A.","short_name":"SFSA","description":"Faktoring dla firm w procedurze uproszczonej. Finansowanie Twojego biznesu - bez dodatkowych zobowiązań. Sprawdź cenę i poznaj korzyści.","start_url":"/","background_color":"#F7F0EB","theme_color":"#5cc3b2","lang":"pl","display":"standalone","icons":[{"src":"logo-72x72.png","sizes":"72x72","type":"image/png"},{"src":"logo-96x96.png","sizes":"96x96","type":"image/png"},{"src":"logo-144x144.png","sizes":"144x144","type":"image/png"},{"src":"logo-192x192.png","sizes":"192x192","type":"image/png"},{"src":"logo-256x256.png","sizes":"256x256","type":"image/png"},{"src":"logo-384x384.png","sizes":"384x384","type":"image/png"},{"src":"logo-512x512.png","sizes":"512x512","type":"image/png"}],"crossOrigin":"anonymous","icon_options":{"purpose":"maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
