import { updateObject } from 'utils/helper';

const RESET = 'app/RESET';
const UPDATE_COOKIE_POLICY = 'app/UPDATE_COOKIE_POLICY';

export const initialState = {
	isCookiePolicy: true
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case RESET:
			return updateObject(state, initialState);
		case UPDATE_COOKIE_POLICY:
			return updateObject(state, ({ isCookiePolicy: payload }));
		default:
			return state;
	}
};

export const resetApp = () => {
	return { type: RESET };
};

export const updateCookiePolicy = value => ({ type: UPDATE_COOKIE_POLICY, payload: value });
