import 'react-virtualized/styles.css';
import 'prevent-pull-refresh';

import { StylesProvider } from '@material-ui/core/styles';
import { persistor, store } from 'ducks/config';
import { CurrencyProvider } from 'providers/CurrencyProvider';
import { FormProvider } from 'providers/FormProvider';
import { HeaderProvider } from 'providers/HeaderProvider';
import { ThemeProvider } from 'providers/ThemeProvider';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
	// Instantiating store in `wrapRootElement` handler ensures:
	//  - there is fresh store for each SSR page
	//  - it will be called only once in browser, when React mounts

	return (
		<Provider store={store}>
			<PersistGate loading={<div />} persistor={persistor}>
				<StylesProvider injectFirst>
					<HeaderProvider>
						<ThemeProvider>
							<CurrencyProvider>
								<FormProvider>
									<App>{element}</App>
								</FormProvider>
							</CurrencyProvider>
						</ThemeProvider>
					</HeaderProvider>
				</StylesProvider>
			</PersistGate>
		</Provider>
	);
};
