import 'moment/locale/pl';

import i18n from 'i18next';
import moment from 'moment/moment';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import pl from './pl.json';

i18n
	.use(initReactI18next)
	.init({
		resources: { en, pl },
		fallbackLng: 'en',
		debug: true,
		ns: ['translations'],
		defaultNS: 'translations',
		keySeparator: '.',
		interpolation: {
			escapeValue: false
		}
	});

moment.locale('pl');
export default i18n.changeLanguage('pl');
