import { DEFAULT } from 'constants/enums';
import React, { createContext } from 'react';
import {useSetState} from 'react-use';

// Default values for advanced form
export const defaultState = {
	formData: {
		id: null,
		isSwipeDisabled: false,
		isLimitOffer: false,
		email: '',
		phone: '',
		preparatoryCommission: null,
		rate: null,
		countries: null,
		industries: null,
		recipients: null,
		paymentAdvanceHeights: null,
		securityTypes: [],
		initialAmount: DEFAULT.INIT_AMOUNT,
		maxAmount: DEFAULT.MAX_AMOUNT,
		initialDays: DEFAULT.INIT_DAYS,
		maxDays: DEFAULT.MAX_DAYS,
		updateHeight: () => {}
	},
	firstPanel : {
		amount: DEFAULT.INIT_AMOUNT,
		checkedInstallment: DEFAULT.INIT_INSTALLMENT,
		profession: '',
		legalForm: ''
	},
	secondPanel : {
		country: DEFAULT.COUNTRY,
		recipientsAmount: 1,
	},
	thirdPanel : {},
	setFormData: () => {},
	setFirstPanel: () => {},
	setSecondPanel: () => {},
	setThirdPanel: () => {}
};

const Context = createContext(defaultState);

function FormProvider({ children }) {
	const [formData, setFormData] = useSetState(defaultState.formData);

	const [firstPanel, setFirstPanel] = useSetState(defaultState.firstPanel);
	const [secondPanel, setSecondPanel] = useSetState(defaultState.secondPanel);
	const [thirdPanel, setThirdPanel] = useSetState(defaultState.thirdPanel);

	return (
		<Context.Provider
			value={{
				formData,
				setFormData,
				firstPanel,
				setFirstPanel,
				secondPanel,
				setSecondPanel,
				thirdPanel,
				setThirdPanel
			}}
		>
			{children}
		</Context.Provider>
	);
}

export { FormProvider };

export default Context;
