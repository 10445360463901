import 'fontsource-montserrat/400-normal.css';
import 'fontsource-montserrat/500-normal.css';
import 'fontsource-montserrat/600-normal.css';
import 'fontsource-montserrat/700-normal.css';
import 'fontsource-lato/400-normal.css';
import 'fontsource-lato/700-normal.css';
import 'lang/config.js';
import 'styles/tailwind.scss';

import wrapWithProvider from './wrap-with-provider';
export const wrapRootElement = wrapWithProvider;

export const onServiceWorkerUpdateReady = () => {
	console.info('Aplikacja została zaktualizowana.');
};
