import { INPUT } from 'constants/enums';
import * as R from 'ramda';

export function hasValidEmail(email) {
	const regex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(String(email).toLowerCase());
}

export function hasValidPolandNip(nip) {
	if(!nip || typeof nip !== 'string') return false;

	const nipWithoutDashes = nip.replace(/-/g, '');
	const regex = /^[0-9]{10}$/;

	if(regex.test(nipWithoutDashes) === false) {
		return false;
	} else {
		const digits = ('' + nipWithoutDashes).split('');
		const checkSum = (6*parseInt(digits[0]) + 5*parseInt(digits[1]) + 7*parseInt(digits[2]) + 2*parseInt(digits[3]) + 3*parseInt(digits[4]) + 4*parseInt(digits[5]) + 5*parseInt(digits[6]) + 6*parseInt(digits[7]) + 7*parseInt(digits[8]))%11;

		return (parseInt(digits[9]) === checkSum);
	}
}

export function hasValidCardNumber(number) {
	const regex = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
	return regex.test(String(number).replace(/ /g, ''));
}

export function hasValidExpiryDate(date) {
	const regex = /^([0-1][0-9]\/[2-9][0-9])/;
	return regex.test(String(date));
}

export function hasValidCvcNumber(number) {
	const regex = /^[0-9]{3,4}$/;
	return regex.test(String(number));
}

export function hasValidFieldNumber(number) {
	const regex = /^\d+/;
	return regex.test(String(number));
}

const getFormattedValue = (value, ratio, options) => {
	if(value < 0) {
		value = R.negate(value);
	}

	value = (value / ratio);
	return value.toLocaleString('de-DE', options);
};

/**
 * Returns the formated Europe currency.
 * @param {float|number|null} value
 * @param {float|number} ratio - default is for PLN
 * @param {string|null} currency - e.q. zł € $
 * @param {string} plus - e.q. '\u00a0\u00a0'
 * @param {string} minus - e.q. '\u00a0-'
 * @return {string} Formated currency.
 */
export function formatFundsEurop(value, ratio = 1, currency = 'zł', plus = '+', minus = '-') {
	const sign = value < 0 ? minus : plus;
	const formatedValue = getFormattedValue(value, ratio, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

	if(plus.length && !currency) {
		return `${sign}${formatedValue}`;
	}

	return currency ? `${sign}${formatedValue} ${currency}` : formatedValue;
}

/**
 * Returns the formated Default currency.
 * @param {float|number|null} value
 * @param {float|number} ratio - default is for PLN
 * @param {string|null} currency - e.q. zł € $
 * @param {string} plus - e.q. '\u00a0\u00a0'
 * @param {string} minus - e.q. '\u00a0-'
 * @return {string} Formated currency.
 */
export function formatFundsTypical(value, ratio = 1, currency = null, plus = '\u00a0', minus = '-') {
	const sign = value < 0 ? minus : plus;
	const formatedValue = getFormattedValue(value, ratio, { maximumFractionDigits: 0 }).replace(/([,.])/g, ' ');

	if(plus.length && !currency) {
		return `${sign}${formatedValue}`;
	}

	return currency ? `${sign}${formatedValue} ${currency}` : formatedValue;
}

export function formatFunds(string, fractionDigits = 2) {
	const formattedValue = parseFloat(string).toFixed(fractionDigits);
	return fractionDigits ? formattedValue.replace('.', ',') : formattedValue;
}

function contextEmpty() {
	let isEmpty = false;

	for (let i = 0; i < arguments.length; i++) {
		if (typeof arguments[i] === 'string' && !arguments[i].toLocaleString().length) {
			isEmpty = true;
		} else if (typeof arguments[i] === 'object' && arguments[i] === null) {
			isEmpty = true;
		} else if (isArray(arguments[i]) && arguments[i].length === 0) {
			isEmpty = true;
		} else if (isObject(arguments[i]) && Object.entries(arguments[i]).length === 0) {
			isEmpty = true;
		}
	}
	return isEmpty;
}

// there is at least one empty argument (string or null)
export function isAnyEmpty() {
	return contextEmpty.apply(this, arguments);
}

export function isObjectAnyEmptyValues(values) {
	for(const [, value] of Object.entries(values)) {
		if(isAnyEmpty(value)) {
			return true;
		}
	}
	return false;
}

export function isObjectAnyEmpty() {
	return contextEmpty.apply(this, arguments);
}

export function varToString(varObj) {
	return Object.keys(varObj)[0];
}

export function to(promise) {
	return promise
		.then(response => [null, keysTo(response)])
		.catch(error => [error]);
}

export function toAll(...args) {
	return Promise.all(args)
		.then(response => [null, keysTo(response)])
		.catch(error => [error]);
}

const isArray = item => {
	return Array.isArray(item);
};

const isObject = item => {
	return item === Object(item) && !isArray(item) && typeof item !== 'function';
};

const toCamel = string => {
	return string.replace(/([-_][a-z])/ig, ($1) => {
		return $1.toUpperCase()
			.replace('-', '')
			.replace('_', '');
	});
};

export const toSnake = string => string.replace(/([A-Z])/g, '_$1').toLowerCase();

export function keysTo(data, func = toCamel) {
	if (isObject(data)) {
		const object = {};

		Object.keys(data)
			.forEach((item) => {
				object[func(item)] = keysTo(data[item], func);
			});

		return object;
	} else if (isArray(data)) {
		return data.map((item) => {
			return keysTo(item, func);
		});
	}

	return data;
}

export const updateObject = (oldObject, properties) => ({ ...oldObject, ...properties });

export const setProperty = (name, value) => {
	if(typeof window !== 'undefined') {
		return document.documentElement.style.setProperty(name, value);
	}
};

export const getProperty = name => {
	if(typeof window !== 'undefined') {
		return cutEmptySpaces(window.getComputedStyle(document.documentElement).getPropertyValue(name));
	}
};

export const val = name => {
	if(typeof window !== 'undefined') {
		return window.getComputedStyle(document.documentElement).getPropertyValue(`--color-${name}`);
	}
};

export const cutEmptySpaces = text => text.replace(/\s/g, '');

export const countryToFlag = isoCode => {
	return (isoCode && typeof String.fromCodePoint !== 'undefined')
		? isoCode.toUpperCase().replace(/./g, (char) =>
			String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode;
};

export const dismissOnSubmitKey = event => {
	if(event.charCode === INPUT.KEY.SUBMIT) {
		event.currentTarget && event.currentTarget.blur();
		event.target && event.target.blur();
		event.preventDefault();
	}
};

export const convertToPln = (value, currencies, currencyCode) => {
	if(!value || !currencies || !currencyCode) return null;
	if(!currencies.length) return null;

	const currencyObj = currencies.find(item => item.code === currencyCode);
	return value * currencyObj.ratio;
};

export const replaceNthString = (string, nth = 3, replaceWith = ' ') => {
	const chars = string.split('');

	for (let i = nth-1; i < chars.length-1; i+=nth) {
		chars[i] = replaceWith;
	}

	return chars.join('');
};

// default - phone numbers
export const formatNthString = (string, nth = 3, joinWith = ' ') => {
	if(!string) return null;

	const result = [];

	while (string.length) {
		result.push(string.substr(0, nth));
		string = string.substr(nth);
	}

	return result.join(joinWith);
};

export function convertToPx(rem) {
	if(typeof document === 'undefined') return rem * 16;
	return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function convertToRem(px) {
	if(typeof document === 'undefined') return px / 16;
	return px / parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function vw(number) {
	if(typeof window === 'undefined' || typeof document === 'undefined') return 320 * number / 100;

	const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	return vw * number / 100;
}

export const isSafariBrowser = () => {
	if(typeof window === 'undefined') return false;

	let result;
	const ua = window.navigator.userAgent;
	const iOS = !!ua.match(/iP(ad|od|hone)/i);
	const hasSafariInUa = !!ua.match(/Safari/i);
	const noOtherBrowsersInUa = !ua.match(/Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox/i);

	if(iOS) { //detecting Safari in IOS mobile browsers
		const webkit = !!ua.match(/WebKit/i);
		result = webkit && hasSafariInUa && noOtherBrowsersInUa;
	} else if(window.safari !== undefined){ //detecting Safari in Desktop Browsers
		result = true;
	} else { // detecting Safari in other platforms
		result = hasSafariInUa && noOtherBrowsersInUa;
	}
	return result;
};
