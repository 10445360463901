import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import React, { createContext, useState } from 'react';
import { setProperty } from 'utils/helper';

const defaultState = {
	theme: {
		overrides: {
			MuiTooltip: {
				popper: {
					zIndex: '99999999999999 !important'
				}
			},
			MuiPopover: {
				root: {
					zIndex: '9999999999999 !important'
				}
			}
			// MuiDialog: { From component PopupContainer }
		},
		typography: {
			'fontFamily': '"Montserrat", "Lato", sans-serif'
		},
		palette: {
			primary: { main: '#3B81B9' },
			secondary: { main: '#25848D' }
		}
	},
	setTheme: () => {}
};

const Context = createContext(defaultState);

function ThemeProvider({ children }) {
	const [theme, updateTheme] = useState(createMuiTheme(defaultState.theme));

	const setTheme = object => {
		updateTheme(createMuiTheme(object));
	};

	const setPrimaryColor = value => {
		setProperty('--color-primary', value);

		const newTheme = {
			...defaultState.theme,
			palette: {
				...defaultState.theme.palette,
				primary: { main: value }
			}
		};

		updateTheme(createMuiTheme(newTheme));
	};

	return (
		<Context.Provider
			value={{
				theme,
				setTheme,
				setPrimaryColor
			}}
		>
			<MuiThemeProvider theme={theme}>
				{children}
			</MuiThemeProvider>
		</Context.Provider>
	);
}

export { ThemeProvider };

export default Context;
