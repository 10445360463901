import { DEFAULT } from 'constants/enums';
import React, { createContext, useState } from 'react';

const defaultState = {
	currencies: null,
	checkedCurrency: DEFAULT.CURRENCY,
	previousCurrency: null,
	setCurrencies: () => {},
	setCheckedCurrency: () => {}
};

const Context = createContext(defaultState);

function CurrencyProvider({ children }) {
	const [currencies, setCurrencies] = useState(defaultState.currencies);
	const [checkedCurrency, setCurrency] = useState(defaultState.checkedCurrency);
	const [previousCurrency, setPreviousCurrency] = useState(defaultState.previousCurrency);

	const setCheckedCurrency = currency => {
		setPreviousCurrency(checkedCurrency);
		setCurrency(currency);
	};

	return (
		<Context.Provider
			value={{
				currencies,
				setCurrencies,
				checkedCurrency,
				previousCurrency,
				setCheckedCurrency
			}}
		>
			{children}
		</Context.Provider>
	);
}

export { CurrencyProvider };

export default Context;
